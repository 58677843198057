export default {
    menu:{
        home: '主 页',
        search: '图包索引',
        tournament: '赛 事',
        about: '关 于',
    },
    home:{
        activities: '最新活动',
        time: '活动时间',
        organizer: '组织者'
    },
    tournament:{
        mainWiki: '主维基',
        quaWiki: '资格赛维基',
        forum: '论坛页',
        masterSheet: '主表格',
        challonge: '对阵表',
        QQ: 'QQ赛事群',
        Discord: 'Discord聊天组',
    },
    about:{
        jackHouse: '叠 屋',
        slogan: '全球最大的4K叠键玩家社区',
        p1: '叠屋，创建于2022年6月1日，本质上是以QQ群为媒介的叠图仓库和叠键玩家交流群。截至2024年9月，已有超过2000名来自世界各地的成员，其中中国玩家最多。你可以在这里获取各式各样的叠练习图，可以接触到4K社区中罕见的叠键专项比赛，还可以与来自世界各地的叠键玩家交流！没有任何要求或门槛，无论是否强叠，我们都欢迎你加入！',
        thanks: '本网站使用 kivo.fun 的碧蓝档案字体，特此致谢',
    },
    search: {
        search: '共',
        search2: '条',
        placeholder: '请输入关键词 (标题或创建者)',
        title: '标题',
        link: '链接',
        emptyText: '无结果',
        creator: '创建者',
        BPM: 'BPM范围',
        DF: '难度范围',
    },
    develop: {
        developing: '开发中...',
    }
}