<template>
  <div class="home">
    <el-row style="padding-top: 1vh" type="flex" justify="center">
      <el-col :lg="12" :xs="24" :sm="24" :md="24">
        <el-carousel height="40vh">
          <el-carousel-item v-for="item in carouselImg" :key="item.id">
            <img :src="item.url" alt="" style="object-fit: scale-down; width: 100%; height: 100%">
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <el-row style="padding-top: 2vh" type="flex" justify="center">
      <el-col :lg="14" :xs="24" :sm="24" :md="24">
        <p class="title">{{ $t('home.activities') }}</p>
        <div v-for="(item, index) in sortedEvents" :key="item.id">
<!--          <div class="bili">
            <p class="videoTitle">MJHC Finals Mappool Showcase</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1xKHCe5EqM&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" width="100%" style="padding-bottom: 2vh"></iframe>
          </div>-->
          <el-card :body-style="{ padding: '0px', background: '#1e1e1e'}" @click.native="goToDetail(item.id)"> <!--此处需要使用@click.native-->
            <div class="card-body">
              <div class="card-body-title">
                <div style="display: inline-block" v-if="eventActive(item.endTime)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks" viewBox="0 0 16 16">
                    <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
                    <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
                  </svg>
                </div>
                <div style="display: inline-block" v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                  </svg>
                </div>
                <span> {{ item.title }}</span>
              </div>
              <div class="card-body-content">
                {{ $t('home.time') }} : {{ item.startTime }} ~ {{ item.endTime }}
                <span style="float: right; padding-right: 3em" v-if="!isMobile">{{ $t('home.organizer') }} : {{ item.organizer }}</span>
                <div class="card-body-content-mobile" v-else>
                  {{ $t('home.organizer') }} : {{ item.organizer }}
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-backtop style="background-color: #e1e1e1">
        <div
        style="{
          height: 100%;
          width: 100%;
          background-color: #e1e1e1;
          text-align: center;
          border-radius: 50%;
          line-height: 40px;
          color: #1e1e1e;
        }">
          ▲
        </div>
    </el-backtop>
  </div>
</template>

<script>
import events from '../assets/events.json'

export default {
  data(){
    return {
      carouselImg: [
        {
          id: 1,
          url: 'https://free2.yunpng.top/2024/10/03/66fe60a9b8120.png',
        },
        {
          id: 2,
          url: 'https://free2.yunpng.top/2024/10/05/670129559c7d9.jpg',
        },
        {
          id: 3,
          url: 'https://free2.yunpng.top/2024/10/05/670129558381f.jpg',
        },
      ],
      events: [],
      sortedEvents: [],
      isMobile: false,
    }
  },
  methods: {
    eventActive(endTime){
      const now = new Date();
      const end = new Date(endTime);
      return now <= end;
    },
    getEvents(){
      if(this.$store.state.langs === 'en'){
        this.sortedEvents = this.events.map(event => {
          return{
            id: event.id,
            title: event.titleEN,
            content: event.contentEN,
            startTime: event.startTime,
            endTime: event.endTime,
            organizer: event.organizer,
          };
        }).sort((a, b) => b.id - a.id);
      }
      else{
        this.sortedEvents = this.events.map(event => {
          return{
            id: event.id,
            title: event.titleZH,
            content: event.contentZH,
            startTime: event.startTime,
            endTime: event.endTime,
            organizer: event.organizer,
          };
        }).sort((a, b) => b.id - a.id);
      }
    },
    goToDetail(id){
      this.$router.push({ path: `/event/${id}` });
    },
    checkScreenSize(){
      this.isMobile = window.innerWidth <= 768;
      if(this.isMobile){
        console.log(this.isMobile);
      }
    },
  },
  mounted() {
    this.events = events;
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.getEvents();
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch:{
    '$i18n.locale'(){ //监听语言变化以改变placeholder语言
      this.getEvents();
    }
  }
}
</script>

<style scoped>
.title{
  text-align: center;
  font-size: 24px;
  color: #e1e1e1;
}
.subtitle{
  text-align: center;
  font-size: 20px;
  color: #aeadad;
}
.videoTitle{
  font-size: 16px;
  color: #aeadad;
  padding-left: 0.5vw;
}
.el-icon-caret-top{
  color: #1e1e1e !important;
}
.activity{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bili{
  position: relative;
  width: 100%; /* 默认宽度100% */
  height: 56.25%; /* 16:9 的宽高比 */
  overflow: hidden; /* 隐藏溢出的内容 */
  margin-bottom: 2vh;
}

iframe{
  width: 100%; /* 宽度填充父容器 */
  height: 315px;
}

.el-card{
  border: #686868 1px solid;
  margin-bottom: 3vh;
  &:hover{
    transform: translateY(-2px);
    transition: transform ease-in-out .2s;
  }
}

.card-body{
  padding-left: 15px;
}

.card-body-title{
  font-size: 18px;
  color: #aeadad;
  padding-top: 0.5em;
  padding-bottom: 10px;
}

.card-body-content{
  font-size: 12px;
  color: #aeadad;
  padding-bottom: 1em;
  padding-left: 1em;
}

.card-body-content-mobile{
  font-size: 12px;
  color: #aeadad;
}

@media (min-width: 768px) {
  .bili {
    max-width: calc(50% - 10px); /* 宽度为容器的一半减去间隔 */
    margin-right: 10px; /* 右边距，为两个视频之间提供间隔 */
  }
}

/* 小型设备，例如手机 */
@media (max-width: 767px) {
  .bili {
    max-width: 100%; /* 在小屏幕上宽度占满整个容器 */
    margin-right: 0; /* 重置右边距 */
  }
}
</style>
